import React, { useContext } from 'react';
import flush from 'just-flush';
import { TestimonialCarousel } from '@stereoagency/ui';
import { LinesContext } from '@/components/LinesContext';

type Props = {
  data: {
    title?: string
    testimonials: {
      author: string
      quote: string
      role?: string
    }[]
  }
};

export default function NvisoTestimonials({ data: { title, testimonials } }: Props) {
  const { enableLines } = useContext(LinesContext);

  return (
    <TestimonialCarousel
      title={title}
      enableLines={enableLines}
      testimonials={flush(testimonials.map((item) => ({
        authorName: item.author,
        testimonial: item.quote,
        authorRole: item.role,
      })))}
    />
  );
}
